import classes from "./footer.module.scss"
const Footer = ({isMobile}) => {
    return ( 
        <>
        <p className={classes.ofert}  style={{ marginBottom: '20px', fontSize: isMobile ? '12px' : '16px' }}>Сайт не является публичной офертой</p>
        <p className={classes.ofert} style={{marginTop: 0, fontSize: isMobile ? '12px' : '16px'}}>ИП Бессолицына огрнип 322890100015428 ИНН 890700677686</p>
        </>
     );
}
 
export default Footer;