import classes from "./fastblock.module.scss"
const Fastblock = () => {
    return ( 
        <main className={classes.main}>
				<div className={classes.titlewrap}>
					<h2>Экстренный вызов электрика</h2>
				</div>
				<div className={classes.subtitlewrap}>
					<p className={classes.subtitle}>Мастер электрик приедет за 30 минут. Все необходимые материалы и инструменты с собой, срочный вызов это:
					</p>
				</div>
				<div className={classes.breakdownswrap}>
					<div className={classes.breakdownsitem}>
						<p>Нет света в квартире</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Запах гари</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Бьют током приборы</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Искрит проводка</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Короткое замыкание</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Не работают розетки</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Выбивает автомат</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Не работает техника</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Нет света в доме</p>
					</div>
					<div className={classes.breakdownsitem}>
						<p>Выбивает УЗО</p>
					</div>
				</div>
				<div className ={classes.breakdownsending}>
					<p>Стоимость работ определяется мастером на месте по прайс листу компании цены могут отличаться на это влияет
						сложность работ и стоимость расходных материалов.</p>
				</div>
			</main>
     );
}
 
export default Fastblock;