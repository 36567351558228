import { useState } from "react";
import Modal from "../modal/Modal";
import classes from "./presentation.module.scss"
const Presentation = ( {modalopen, openModal, setModalopen, phoneNumber}) => {
  const isMobile = window.innerWidth <= 505;
	const [error, setError] = useState('');

	function nameSetting(event) {
	 setError(event.trim().length==0)
 }
    return ( <>
 <main>
         <div className={classes.cardWrapper}>
          <div className={classes.card}>
            <div className={classes.cardImg + " " + classes.one}></div>
            {isMobile ? (<p className={classes.cardText}>
            Приедем за 30 минут <br /> <span className={classes.span}>или в удобное для  Вас время</span> 
            </p>):
            (<p className={classes.cardText}>
            Приедем за 30 минут <br /> <span className={classes.span}>или в удобное для <br /> Вас время</span> 
            </p>)}
        </div>
          <div className={classes.card}>
            <div className={classes.cardImg + " " + classes.two}></div>
            {isMobile ? ( <p className={classes.cardText}>
            Работаем  <br /> <span className={classes.span}>только профессиональным   инструментом</span> 
            </p>):
            ( <p className={classes.cardText}>
            Работаем  <br /> <span className={classes.span}>только профессиональным <br /> инструментом</span> 
            </p>)}
        </div>

          <div className={classes.card}>
            <div className={classes.cardImg + " " + classes.three}></div>
            <p className={classes.cardText}>
            Работаем  <br /> <span className={classes.span}>с физическими и юридическими лицами</span> 
            </p>
        </div>
        <div className={classes.card}>
            <div className={classes.cardImg + " " + classes.four}></div>
            <p className={classes.cardText}>
            Гарантия качества <br /> <span className={classes.span}>От трех лет в зависимости от типа работ</span> 
            </p>
        </div>
</div>
<div className={classes.callWrapper}>
<a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
<button onClick={openModal} className={classes.btnRequest}>Оставить заявку</button>
</div>
</main>
<Modal modalopen={modalopen} 
       setModalopen={setModalopen}
       error = {error}
       nameSetting = {nameSetting}
       >
   </Modal>
   </>
     );
}

export default Presentation;