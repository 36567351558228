import { useState } from "react";
import Modal from "../modal/Modal";
import classes from "./../presentation/presentation.module.scss"
import SimpleSlider from "../slider/Slider";

const Professionals = ( {modalopen, openModal, setModalopen, phoneNumber}) => {
 
 
	const [error, setError] = useState('');

	function nameSetting(event) {event && setError(event.trim().length<12) }

    return ( <>
 <main >
  <section className={classes.prof_workers}>
 <div className={classes.title_wrap}>
					<h2>У нас работают только профессиональные электрики  <span className={classes.spanprof}>с допуском</span></h2>
				</div>
				<div className={classes.subtitle_wrap}>
					<p>Все электрики в штате компании</p>
      
				</div>
        
  <SimpleSlider/>

    

<div className={classes.callWrapper}>
<a className={classes.btnCall} href={`tel: ${phoneNumber}`} >позвонить</a>
<button onClick={openModal} className={classes.btnRequest}>Оставить заявку</button>
</div>
</section>
</main>
<Modal modalopen={modalopen} 
       setModalopen={setModalopen}
       error = {error}
       nameSetting = {nameSetting}
       >       
</Modal>
   </>
     );
}

export default Professionals;
