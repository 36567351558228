import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import './index.scss';
import './reset.scss';
import './mixins.scss';
import './fonts/inter/stylesheet.css'
   
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<App/>)
