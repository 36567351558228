import { useRef, useEffect, useState } from 'react'
import classes from "./modal.module.scss" 
import {createPortal } from 'react-dom'
import Input from 'react-phone-number-input/input'

export default function Modal({ modalopen,  setModalopen, error, nameSetting}) {
  const [focus, setFocus] = useState(false);
  const dialog = useRef();
  const inputRef = useRef(); // Добавляем новый ref для input

  const [value, setValue] = useState("");

  useEffect(()=>{
    nameSetting(value)
  },[value])

  useEffect(() => {
    if (modalopen) {
      dialog.current.showModal()
      document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape') {
            document.body.style.overflow = 'unset';
            setModalopen(false)
        }
    });  
    } else {
      dialog.current.close()
    }
  }, [modalopen])

  return createPortal (
    <dialog ref={dialog}><div className={classes.wrap} >  
    <div className = {classes.question_form__img} >
      <p>Оставьте заявку</p>
    </div>
    <div className = {classes.question_form__title}>
      <p className = {classes.question_form__title1}>Перезвоним в течение 1 минуты</p>
      
    </div>
    <div className = {classes.question_form__form}>
    <form action="telegram.php" method="post">

          <Input
            name="phone"  
            className={classes.phone} 
            style={{border: error&focus ? 'red 1px solid' : focus?'green 1px solid': '1px solid #9FACDC'}}  
            required 
            onFocus={()=> {setFocus(true)}} 
            onBlur={()=> setFocus(false)}  
            ref={inputRef}
            country="RU"
            maxLength="16"
            minLength="16"
            international
            withCountryCallingCode
            value={value}
            onChange={setValue}
            />

        <button  className= { error? classes.btn1 + " " + classes.disable :classes.btn1}  type="submit" >Отправить</button>
        <button className={classes.close_btn} onClick={()=>{setModalopen(false);setValue(""); document.body.style.overflow = 'unset'} } type= "button" >×</button>
                </form>
      </div>
    </div> 
    </dialog>,
    document.getElementById('modal')

  );

}

