import classes from "./../questions/questions.module.scss";

const Mape = ({phoneNumber}) => {
	
    return ( 
        <section className={classes.map}>
			<main className={classes.container}>
				<div className={classes.titlewrap}>
					<h2>Зона обслуживания -  <span>Москва и МО</span></h2>
				</div>
				<div className={classes.subtitlewrap}>
					<p>Вызвать электрика нашего сервиса можно в любую точку Москвы и области
					</p>
				</div>

				<div className={classes.halfwrap}>
					<div className={classes.halfitem}>
						<div className={classes.questionform}>
								<img className={classes.imgmap}  src={require("./kartka-moskvy.webp")} alt="Карта"/>
						</div>
					</div>	 
					<div className={classes.halfitem}>
						<div className={classes.accordion}>

							<div className={classes.accordion__item}>
								<div className={classes.accordion__head} style={{cursor: 'unset'}} >
									<p>Тел: {phoneNumber}</p>
								</div>
							</div>

							<div className={classes.accordion__item}>
								<div className={classes.accordion__head} style={{cursor: 'unset'}} >
									<p>E-mail: msk89088644627@yandex.ru</p>
								</div>
							</div>

							<div className={classes.accordion__item}>
								<div className={classes.accordion__head} style={{cursor: 'unset'}}>
									<p>Работаем круглосуточно</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</section>
     );
}
 
export default Mape;