import classes from "./header.module.scss"
import  logo  from "./imgs/lightning.png";
const Header = ({phoneNumber}) => {
  
    return ( 
    <>
       <main className= {classes.headerwrap}>
         <div className={classes.top_wrapper}>
            <div className={classes.logowrap} >
              <img className= {classes.imgsize} src={logo} alt="Логотип"/>
              <h1 className={classes.fontsize}> Мастер Электрик </h1>
            </div>
            <div className={classes.textPhone}>
            <p style={{justifySelf:'center'}} className={classes.textlight}>срочный вызов 24/7</p>
            
          </div>
          </div>
        
          <div className={classes.mainblock}>
            <div className= {classes.bgimg}>
           
              <h2 className= {classes.mainblock_text}>Электрика <br /> все виды работ</h2>
              <a className={classes.btntwo} href={`tel: ${phoneNumber}`}> {phoneNumber}</a>
          </div>
      </div>
       </main>
   </>
     );
}
 
export default Header;