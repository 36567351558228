import Slider from "react-slick";
import classes from "./../presentation/presentation.module.scss";


const SimpleSlider = () => {
    const isMobile = window.innerWidth <= 505;
    const isTable = window.innerWidth <= 992;
    let settings;
    isMobile?
    settings = {
   
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 500,
        
      } :  isTable? settings = {
   
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 500,
            
          } :
            settings =    {infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 500,}

    return (     
 
        <div className="slider-container">
    <Slider {...settings}>
     
          <div className={classes.card + " "+ classes.slide}>
            <div className={classes.cardImg + " " + classes.five}></div>
            {isMobile ? (<p className={classes.cardText}>
            Без опозданий <br /> <span className={classes.span}>приедут в строго согласованное время</span> 
            </p>):
            (<p className={classes.cardText}>
            Без опозданий <br /> <span className={classes.span}>приедут в  <br />строго согласованное время </span> 
            </p>)}
        </div>
          <div className={classes.card +" "+ classes.slide}>
            <div className={classes.cardImg + " " + classes.six}></div>
            {isMobile ? ( <p className={classes.cardText}>
            Выполнят работы  <br /> <span className={classes.span}>аккуратно и быстро, без перекуров</span> 
            </p>):
            ( <p className={classes.cardText}>
            Выполнят работы  <br /> <span className={classes.span}>аккуратно и быстро,  <br />без перекуров </span> 
            </p>)}
        </div>

          <div className={classes.card+ " "+ classes.slide}>
            <div className={classes.cardImg + " " + classes.seven}></div>
            {isMobile ? ( <p className={classes.cardText}>
              Уберут за собой  <br /> <span className={classes.span}>строительный мусор</span> 
            </p>):
           ( <p className={classes.cardText}>
            Уберут за собой  <br /> <span className={classes.span}>строительный <br /> мусор</span> 
            </p>)}
        </div>
        <div className={classes.card+ " "+ classes.slide}>
            <div className={classes.cardImg + " " + classes.eight}></div>
            {isMobile ? ( <p className={classes.cardText}>
              Работают только  <br /> <span className={classes.span}>профессиональным инструментом</span> 
            </p>):
            (<p className={classes.cardText}>
            Работают только <br /> <span className={classes.span}> профессиональным <br /> инструментом</span> 
            </p>)}
        </div>

      </Slider>
     </div>
   
   );
}
 
export default SimpleSlider;